<template>
  <div :class="$style.home">
    <div :class="$style.head">
      <img v-if="data.venue.cover" :class="$style.img" :src="data.venue.cover.cover" />
      <div :class="$style.text">
        <p :class="$style.title">{{ data.venue.name }}</p>
        <p :class="$style.desc">{{ data.venue.location }}</p>
      </div>
    </div>
    <div :class="$style.box">
      <div :class="$style.left">
        <div :class="$style.title">
          场馆服务
          <i :class="$style.add" class="ri-add-circle-line" @click="onAdd()" />
        </div>
        <div>
          <div
            v-for="item in fixed"
            :key="item.id"
            :class="[$style.item, item.id === active.id ? $style.itemActive : null]"
            @click="onChoose(item.id, item)"
          >
            <i :class="[$style.icon, item.icon]" />
            <p>{{ item.name }}</p>
            <i :class="$style.fixed" class="ri-pushpin-line" />
          </div>
          <Draggable v-model="data.services" ghost-class="ghost" handle=".mover" tag="tbody" @change="onChange">
            <div
              v-for="item in data.services"
              :key="item.id"
              :class="[$style.item, item.id === active.id ? $style.itemActive : null]"
              @click="onChoose('custom', item)"
            >
              <img :class="$style.mover" class="mover" :src="oss + '/manage/icon/default/sort.png'" />
              <i :class="$style.icon" class="ri-folder-3-line" />
              <p>{{ item.name }}</p>
              <a-dropdown :trigger="['click']">
                <i :class="$style.more" class="ri-more-fill" />
                <a-menu slot="overlay">
                  <a-menu-item>
                    <Bj-link @click="onDelete(item)">
                      <i class="ri-delete-bin-7-line" />
                      删除服务
                    </Bj-link>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </div>
          </Draggable>
        </div>
      </div>

      <div :class="$style.right">
        <div :class="$style.header">
          {{ active.name }}
          <i :class="$style.icon" class="ri-edit-line" @click="editService()" />
          <BjSwitch
            v-if="active.id === 'appointment'"
            v-model="active.status"
            size="default"
            :class="$style.right"
            @change="appChange"
          />
          <BjSwitch
            v-if="active.id === 'around'"
            v-model="around.enable"
            size="default"
            :class="$style.right"
            @change="aroundChange"
          />
          <BjSwitch
            v-if="active.id !== 'around' && active.id !== 'appointment'"
            v-model="custom.status"
            size="default"
            :class="$style.right"
            @change="customChange"
          />
        </div>

        <div :class="$style.content">
          <div v-if="active.id === 'appointment'">
            <a-row :class="$style.appbox">
              <a-col span="12" :class="$style.title">预订列表</a-col>
              <a-col span="12" class="text-right">
                <BjButton type="primary" @click="onServceAdd()">
                  <i class="ri-add-line left" />
                  添加预订
                </BjButton>
              </a-col>
            </a-row>
            <a-table :columns="columns" :data-source="list" :pagination="false" row-key="id">
              <template #action="item">
                <bj-link type="primary" @click="onEdit(item)"> 编辑 </bj-link>
                <a-popconfirm title="确定要删除吗？" placement="topLeft" @confirm="onAppDelete(item)">
                  <bj-link type="danger"> 删除 </bj-link>
                </a-popconfirm>
              </template>
            </a-table>
          </div>

          <div v-if="active.id === 'around'">
            <div :class="$style.title">设置周边</div>
            <div class="layout mt-20">
              <div class="label">附近的厕所</div>
              <div class="value line-40">
                <BjSwitch v-model="around.toilet" size="default" @change="aroundChange" />
                <p class="tip mt-10">是否展示场馆附近的厕所</p>
              </div>
            </div>
            <div class="layout">
              <div class="label">附近的停车场</div>
              <div class="value line-40">
                <BjSwitch v-model="around.park" size="default" @change="aroundChange" />
                <p class="tip mt-10">是否展示场馆附近的停车场</p>
              </div>
            </div>
          </div>

          <div v-if="active.id !== 'around' && active.id !== 'appointment'">
            <div :class="$style.title">服务设置</div>
            <div class="layout mt-20">
              <div class="label">服务链接</div>
              <div :class="$style.noForm" class="value line-40">
                <BjInput v-model="custom.label" class="mb-10" placeholder="请设置服务跳转的链接">
                  <div slot="suffix">
                    <span :class="$style.link" @click="onAddLink()">
                      <i class="ri-link" />
                      设置链接
                    </span>
                  </div>
                </BjInput>
                <p class="tip mt-10">服务链接可设置为小程序或H5</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <chooseLink :visible.sync="visible.link" :data="custom.link" @change="getLink" />
    <bj-modal
      :title="title"
      :visible="visible.service"
      :width="550"
      :body-style="{ height: '400px' }"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div :class="$style.modal">
        <BjInput
          v-model="edit.name"
          label-align="left"
          v-bind="layout"
          rules="required"
          tooltips
          placeholder="请输入服务名称"
          label="服务名称"
        />
        <p :class="$style.pr" class="tip mt-10">设置场馆服务的名称，便于用户了解其功能。</p>
        <BjValidationItem rules="required" label-align="left" label="背景素材" v-bind="layout">
          <selectMaterial :data="edit.material" :allow="['image']" @change="swiperChange" />
          <p class="tip mt-10">图片建议尺寸210px*120px；大小不超过2M；格式支持JPG、PNG。</p>
        </BjValidationItem>
      </div>
    </bj-modal>
  </div>
</template>

<script>
import _ from 'lodash'
import Draggable from 'vuedraggable'

import chooseLink from '@/components/chooseLink'
import selectMaterial from '@/components/selectMaterial'
import { appVenueService } from '@/service'

const service = new appVenueService()

export default {
  name: 'Home',
  components: {
    selectMaterial,
    Draggable,
    chooseLink,
  },
  data() {
    return {
      visible: {
        service: false,
        link: false,
      },
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },
      edit: {
        name: null,
        material: {},
      },
      title: '服务添加',
      data: {
        venue: {},
      },
      list: [],
      active: {
        name: '场馆预订',
        id: 'appointment',
        status: 0,
      },
      around: {
        enable: 0,
        park: 0,
        toilet: 0,
      },
      custom: {
        id: null,
        name: null,
        link: null,
        material: {},
        status: 0,
      },
      id: null,
      isAdd: true,
      fixed: [
        {
          name: '场馆预订',
          id: 'appointment',
          icon: 'ri-ticket-line',
        },
        {
          name: '场馆周边',
          id: 'around',
          icon: 'ri-focus-3-line',
        },
      ],
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '预订类型',
          dataIndex: 'type_name',
        },
        {
          title: '包含条目',
          dataIndex: 'sku_num',
        },
        {
          title: '预订周期',
          dataIndex: 'cycle',
          customRender: value => value + '天内',
        },
        {
          title: '预订方式',
          dataIndex: 'method',
          customRender: value => (value === 1 ? '预约预订' : '支付预订'),
        },
        {
          title: '添加时间',
          dataIndex: 'created_at',
        },
        {
          title: '管理',
          width: 150,
          scopedSlots: {
            customRender: 'action',
          },
        },
      ]
    },
  },
  created() {
    this.id = this.$route.query.id
    this.getInfo()
    this.getList()
    this.getAround()
  },
  methods: {
    async getList() {
      const { data } = await service.getAppList({
        venue_id: this.id,
      })
      this.list = data
    },
    onAdd() {
      this.isAdd = true
      this.visible.service = true
      this.edit = {
        name: null,
        material: {},
      }
    },
    editService() {
      this.isAdd = false
      this.visible.service = true
      this.title = '服务编辑'
      if (this.active.id === 'appointment') {
        this.edit = {
          name: this.data.appointment.name,
          material: this.data.appointment.material,
        }
      }
      if (this.active.id === 'around') {
        this.edit = {
          name: this.data.around.name,
          material: this.data.around.material,
        }
      }
      if (this.active.id !== 'appointment' && this.active.id !== 'around') {
        this.edit = {
          name: this.custom.name,
          material: this.custom.material,
        }
      }
    },
    async onChange() {
      await service.sort({
        venue_id: this.id,
        sort: this.data.services.map(({ id }) => id),
      })
      this.getInfo()
    },
    async getInfo() {
      const { data } = await service.getInfoService({
        venue_id: this.$route.query.id,
      })
      this.data = data
      this.fixed = [
        {
          name: data.appointment.name,
          id: 'appointment',
          icon: 'ri-ticket-line',
          status: data.appointment.status,
        },
        {
          name: data.around.name,
          id: 'around',
          icon: 'ri-focus-3-line',
          status: data.around.status,
        },
      ]
      if (this.active.id === 'appointment') {
        this.active.name = data.appointment.name
        this.active.status = data.appointment.status
      }
      if (this.active.id === 'around') {
        this.active.name = data.around.name
        this.around.enable = data.around.status
      }
      if (this.active.id !== 'appointment' && this.active.id !== 'around') {
        data.services.map(item => {
          if (item.id === this.active.id) {
            this.onChoose('custom', item)
          }
        })
      }
    },
    async getAround() {
      const { data } = await service.getAround({
        venue_id: this.id,
      })
      this.around = {
        enable: data.enable,
        park: data.park,
        toilet: data.toilet,
      }
    },
    async handleOk() {
      if (this.isAdd) {
        await service.saveService({ ...this.edit, venue_id: this.id })
        this.visible.service = false
        this.$message.success('保存成功')
        this.getInfo()
      } else {
        if (this.active.id === 'appointment') {
          await service.saveName({ ...this.edit, venue_id: this.id, id: this.active.id })
          this.visible.service = false
          this.$message.success('保存成功')
          this.getInfo()
        } else if (this.active.id === 'around') {
          await service.saveAround({ ...this.edit, venue_id: this.id, id: this.active.id, enable: this.around.enable })
          this.visible.service = false
          this.$message.success('保存成功')
          this.getInfo()
        } else {
          await service.saveService({ ...this.edit, venue_id: this.id, id: this.active.id })
          this.visible.service = false
          this.$message.success('保存成功')
          this.getInfo()
        }
      }
    },
    handleCancel() {
      this.visible.service = false
    },
    async onDelete(item) {
      await service.deleteService({
        id: item.id,
      })
      this.$message.success('删除成功')
      this.onChoose('appointment', { name: '场馆预订', id: 'appointment', icon: 'ri-ticket-line' })
      this.getInfo()
    },
    swiperChange(data) {
      this.edit.material = { type: 'image', ...data[0] }
    },
    onChoose(type, item) {
      if (type === 'appointment') {
        this.active.name = item.name
        this.active.id = 'appointment'
        this.active.status = item.status
      }
      if (type === 'around') {
        this.active.name = item.name
        this.active.id = 'around'
        this.around.enable = item.status
      }
      if (type === 'custom') {
        this.active.name = item.name
        this.active.id = item.id
        // ---
        this.custom.id = item.id
        this.custom.name = item.name
        this.custom.material = item.material
        // debugger
        this.custom.link = item.link
        this.custom.label = item.link.label
        this.custom.status = item.status
        if (!item.link.type) {
          this.custom.link = null
          this.custom.label = null
        }
      }
    },
    async onAppDelete(item) {
      await service.deleteAppList({
        appointment_id: item.id,
      })
      this.$message.success('删除成功')
      this.getList()
    },
    async appChange() {
      await service.saveName({ ...this.active, venue_id: this.id })
      this.getInfo()
    },
    async aroundChange() {
      await service.saveAround({ ...this.around, venue_id: this.id })
      this.getInfo()
    },
    async customChange() {
      await service.saveCustom({
        venue_id: this.id,
        ...this.custom,
      })
      this.getInfo()
    },
    async getLink(data) {
      this.custom.link = _.cloneDeep(data)
      this.custom.label = this.custom.link.label
      await service.saveCustom({
        venue_id: this.id,
        ...this.custom,
      })
      this.getInfo()
      this.$message.success('保存成功')
    },
    onAddLink() {
      this.visible.link = true
    },
    onServceAdd() {
      this.$router.push({
        name: 'appVenueManageServiceAdd',
        query: {
          id: this.id,
        },
      })
    },
    onEdit(item) {
      this.$router.push({
        name: 'appVenueManageServiceAdd',
        query: {
          id: this.id,
          serve_id: item.id,
        },
      })
    },
  },
}
</script>

<style lang="less" module>
.home {
  .box {
    display: flex;
  }

  .left {
    width: 200px;
    min-height: calc(100vh - 252px);
    margin-right: 20px;
    padding: 20px;
    background: #fff;

    .title {
      position: relative;
      margin-bottom: 14px;
      color: #000;
      font-size: 16px;

      .add {
        position: absolute;
        top: -2px;
        right: 0;
        color: @primary-color;
        font-size: 20px;
      }
    }

    .item {
      position: relative;
      display: flex;
      align-items: center;
      width: 160px;
      height: 44px;
      padding: 0 10px 0 12px;
      border-radius: 6px;
      cursor: pointer;

      p {
        flex: 1;
        margin-bottom: 0;
      }

      .icon {
        margin-right: 8px;
        color: #000;
        font-size: 20px;
      }

      .more {
        display: none;
        color: @primary-color;
        font-size: 18px;
      }

      .fixed {
        color: #bbb;
      }

      .mover {
        position: absolute;
        left: -15px;
        display: none;
        width: 20px;
        height: 20px;
      }
    }

    .item:hover {
      background: #fafafa;

      .mover {
        display: block;
      }

      .more {
        display: block;
      }
    }

    .item-active {
      color: @primary-color !important;
      background: #fafafa !important;

      i {
        color: @primary-color !important;
      }
    }
  }

  .head {
    display: flex;
    align-items: center;
    width: 100%;
    height: 84px;
    margin-bottom: 20px;
    padding: 0 14px;
    background: #fff;

    .img {
      width: 56px;
      height: 56px;
      margin-right: 14px;
      border-radius: 6px;
      object-fit: cover;
    }

    .text {
      flex: 1;

      .title {
        margin-bottom: 4px;
        color: #000;
        font-size: 16px;
      }

      .desc {
        margin-bottom: 0;
        color: #5c5c5c;
        font-size: 13px;
      }
    }
  }

  .right {
    flex: 1;

    .header {
      position: relative;
      width: 100%;
      height: 44px;
      padding: 0 20px;
      font-size: 16px;
      line-height: 44px;
      background: #fff;

      .icon {
        margin-left: 10px;
        color: @primary-color;
        font-size: 16px;
      }

      .right {
        position: absolute;
        top: 14px;
        right: 20px;
      }
    }

    .content {
      width: 100%;
      height: calc(100vh - 342px);
      margin-top: 20px;
      padding: 20px;
      background: #fff;

      .appbox {
        margin-bottom: 20px;
        line-height: 44px;
      }

      .title {
        color: #000;
        font-size: 16px;
      }

      .link {
        display: inline-block;
        width: 84px;
        height: 28px;
        color: #fff;
        font-size: 12px;
        line-height: 28px;
        text-align: center;
        background: @primary-color;
        border-radius: 6px;
        cursor: pointer;
      }
    }
  }
}

.modal {
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }

  .pr {
    padding-left: 129px;
  }
}

.noForm {
  :global {
    .ant-form-item {
      margin-bottom: 10px;
    }
  }
}
</style>
